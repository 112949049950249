// columnDefs.ts
import { ColDef } from 'ag-grid-community';
import StatusDropdown from '../components/StatusDropdown';
import SubscriptionLink from '../components/SubscriptionLink';
import WiredData from '../components/WiredData';
import ReturnCell from '../components/ReturnCell';
import ConfirmWired from '../components/ConfirmWired';
import { formatFloorNumber } from 'utils/currencySymbols';


export const columnDefs: ColDef[] = [
  {
    headerName: 'Entity Name',
    field: 'entity_name',
  },
  {
    headerName: 'Company',
    field: 'company',
  },
  {
    headerName: 'Status',
    field: 'status',
    cellRenderer: StatusDropdown
  },
  {
    headerName: 'Date',
    field: 'timestamp',
    sortable: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(), // Ensure correct syntax
  },
  {
    headerName: 'Invested',
    field: 'amount',
    valueFormatter: (params) => `$${formatFloorNumber(params.value)}`, // Ensure correct syntax
  },
  {
    headerName: 'Current value',
    field: 'current_value',
    valueFormatter: (params) => params.value ? `$${formatFloorNumber(params.value)}` : "N/A",
  },
  {
    headerName: 'Return %',
    field: 'return',
    cellRenderer: ReturnCell
  },
  {
    headerName: 'Documents',
    field: 'signature_id',
    cellRenderer: SubscriptionLink,
    minWidth: 100, 
  },
  {
    headerName: '',
    field: '',
    cellRenderer: WiredData
  },
  {
    headerName: '',
    field: '',
    cellRenderer: ConfirmWired
  },
];
