import React from 'react';

interface ReturnCellProps {
  value: number | null | undefined;
}

const ReturnCell: React.FC<ReturnCellProps> = ({ value }) => {
  if (value === null || value === undefined) {
    return <div style={{ color: '#6c757d' }}>N/A</div>; // Gray for no data
  }

  const isProfit = value >= 0;
  const color = isProfit ? 'rgb(64, 192, 87)' : 'rgb(250, 82, 82)'; // Green for profit, red for loss
  const formattedValue = `${isProfit ? '+' : '-'}${value.toFixed(2)}%`;

  return (
    <div style={{ color, fontWeight: 'bold' }}>
      {formattedValue}
    </div>
  );
};

export default ReturnCell;
