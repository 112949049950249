import React from 'react';
import { IconCheck } from '@tabler/icons';
import { Button } from '@mantine/core';
import { IUboType } from "models/invest/entity.api.model";
import UboTable from './UboTable';

interface EntityCreatedProps {
  ubos: IUboType[]; // Array of UBO names or emails
  onClose: () => void; // Callback for the OK button
}

const EntityCreated: React.FC<EntityCreatedProps> = ({ ubos, onClose }) =>  (
    <div className="entity-created-container">
      <div className="check-icon-container">
        <IconCheck className="check-icon" />
      </div>
      <p className="success-message">
        Entity has been successfully created! Please complete the <strong>KYC</strong> / <strong>KYB</strong> process to proceed with your investment.
      </p>

      {ubos.length > 0 && 
        <>
          <p className="email-notification">
            We have sent an email to the listed UBOs for KYC. It may take a few moments to be delivered.
          </p>
         <UboTable ubos={ubos} />
        </>
      }  
      
      <Button className="ok-button" onClick={onClose} variant="filled" color="green">
        OK
      </Button>
    </div>
  );

export default EntityCreated;
