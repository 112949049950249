// portfolio.service.ts
import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../../utils/apiUtils';
import { RootState } from 'store/store';

interface Investment {
  total: number;
  company: string;
  timestamp: string;
  deal_id: string;
  signature_id: string;
}

interface IPortfolio {
  commitments: Investment[];  
  investments: Investment[];
  current_value: number;
  gain_loss: number;
  return: number;
  total_committed: number;
  total_invested: number;
}

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getPortfolio: builder.query<IPortfolio, void>({
      query: () => 'user/invest/totals?currency=USD',
    }),
  }),
});

export const { useGetPortfolioQuery } = portfolioApi;
