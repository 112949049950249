import React from 'react';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust opacity here
    zIndex: 9999,
  },
  spinner: {
    width: 50,
    height: 50,
    border: '5px solid #ccc',
    borderTop: `5px solid ${theme.colors.blue[6]}`, // Primary color
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
}));

const LoadingOverlay: React.FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.overlay}>
      <div className={classes.spinner} />
    </div>
  );
};

export default LoadingOverlay;
