import React from 'react';
import Persona from 'persona';
import { Modal } from '@mantine/core';

interface PersonaComponentProps {
  inquiryId: string;
  sessionToken: string;
  // userSub: string | undefined;
  onComplete: () => void;
  onCloseModel: () => void;
  opened: boolean
}

const PersonaComponent: React.FC<PersonaComponentProps> = ({ inquiryId, sessionToken, onComplete, onCloseModel, opened }) => (
  <Modal
    opened={opened}
    onClose={onCloseModel}
    title="Verify your Information"
    size="50%"
    overlayBlur={3}
    overlayOpacity={0.55}
  >
    <div className="personna-frame">
      <Persona.Inquiry
        inquiryId={inquiryId}
        sessionToken={sessionToken}
        environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID}
        onComplete={onComplete}
        onCancel={onComplete}
      />
  </div>
  </Modal>
);

export default PersonaComponent;
