import React from 'react';
import { Modal, Stack, Title, Text, Button } from '@mantine/core';
import UboTable from "components/Common/Entity/components/UboTable";
import "./invest.scss";

interface DynamicMessageModalProps {
  isVisible: boolean;
  onClose: () => void;
  type: 'static-message' | 'ubo-message'; // Add more types as needed
  data?: any;
}

const DynamicMessageModal: React.FC<DynamicMessageModalProps> = ({
  isVisible,
  onClose,
  type,
  data,
}) => {
  const renderContent = () => {
    switch (type) {
      case 'static-message':
        return (
                <Stack>
                    <Title order={3} weight={550}>We are waiting on your documents</Title>

                    <Title order={4} weight={550}>For investing via {data?.legal_name}, we&apos;d need the following for regulatory purposes:</Title>
                    <Text mt={4} mb={-15}>1. A simple declaration from you stating:</Text>

                    <Text size="sm" mt={4}>-  The entity has not been formed solely for the transaction being contemplated currently;</Text>
                    <Text size="sm" mt={-15}>- That the entity has assets worth $5M USD; OR that all shareholders of the entity are individually accredited investors as per SEC guidelines.</Text>

                    <Text mt={4} mb={-15}>2. Certificate of incorporation and passport and address proof for anyone who has a claim to 25% or more of the entity&apos;s assets, as well as a control person for the entity.</Text>

                    <Text mt={8}> <b>Please email them to </b> <a href="mailto:support@tokenfolio.co">support@tokenfolio.co</a> .</Text>
             </Stack>
        );

      // Add more cases for different `type` values
      case 'ubo-message':
        return (
            <Stack>
            <Title order={3} weight={550}>
              KYB Verification Completed
            </Title>
            <Text>
              Your KYB verification process has been successfully completed. However, we are still waiting for the following UBO verification(s) to be finalized. Please ensure all UBOs complete their verification at the earliest to proceed with your investment.
            </Text>

             <p className="email-notification">
                An email has been sent to the following UBOs for KYC:
             </p>

            <UboTable 
                showStatusColumn
                ubos={data?.ubos}    
            />
          </Stack>
        );

      default:
        return <Text>No content available for this type.</Text>;
    }
  };

  return (
    <Modal opened={isVisible} onClose={onClose} size="50%">
      {renderContent()}
       <div className="footer-button">  
        <Button className="ok-button" onClick={onClose} variant="filled" color="green">
            OK
        </Button>
       </div>  
    </Modal>
  );
};

DynamicMessageModal.defaultProps = {
    data: undefined
}

export default DynamicMessageModal;
