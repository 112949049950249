// src/utils/currencySymbols.ts
export const currencySymbols: Record<string, string> = {
    USD: '$',
    JPY: '¥',
    BGN: 'лв',
    CZK: 'Kč',
    DKK: 'kr',
    GBP: '£',
    HUF: 'Ft',
    PLN: 'zł',
    RON: 'lei',
    SEK: 'kr',
    CHF: 'CHF',
    ISK: 'kr',
    NOK: 'kr',
    TRY: '₺',
    AUD: '$',
    BRL: 'R$',
    CAD: '$',
    CNY: '¥',
    HKD: 'HK$',
    IDR: 'Rp',
    ILS: '₪',
    INR: '₹',
    KRW: '₩',
    MXN: '$',
    MYR: 'RM',
    NZD: '$',
    PHP: '₱',
    SGD: 'S$',
    THB: '฿',
    ZAR: 'R',
    EUR: '€',
  };
  
export const getCurrencySymbol = (currencyCode: string): string => currencySymbols[currencyCode.toUpperCase()] || currencyCode.toUpperCase();

/**
 * Formating the giving number in commas
 */
export function formatNumberWithCommas(amount: number | string): string {
  const num = typeof amount === 'number' ? amount : parseFloat(amount);
  if (num >= 1e9) {
    return `${Math.floor(num / 1e9)}B`; // Format as billions
  }
  if (num >= 1e6) {
    return `${Math.floor(num / 1e6)}M`; // Format as millions
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format with commas
}

export function formateInvestmentAmount(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumber = (price: string | number): string => Intl.NumberFormat('en-US').format(Number(price));
export const formatFloorNumber = (price: string | number): string =>  Intl.NumberFormat('en-US').format(Math.floor(Number(price)));
