// UBOFields.tsx
import React from 'react';
import { TextInput, Select, Button, Group, NumberInput } from '@mantine/core';
import { IconPlus, IconTrash } from "@tabler/icons"
import { IUboType } from "models/invest/entity.api.model";


interface UBOFieldsProps {
  ubos: IUboType[];
  countries?: { label: string; value: string }[];
  onAddUBO: () => void;
  onRemoveUBO: (index: number) => void;
  onChangeUBO: (index: number, field: keyof IUboType, value: string | number) => void;
}

const UBOFields: React.FC<UBOFieldsProps> = ({ ubos, countries, onAddUBO, onRemoveUBO, onChangeUBO }) => (
  <>
    {ubos.map((ubo, index) => (
      <Group key={index}  mt="sm" className="ubo-box"> {/* eslint-disable-line react/no-array-index-key */}
        <TextInput label="First Name" placeholder="Enter first name" required value={ubo.first_name} onChange={(e) => onChangeUBO(index, 'first_name', e.target.value)} />
        <TextInput label="Last Name" placeholder="Enter last name" required value={ubo.last_name} onChange={(e) => onChangeUBO(index, 'last_name', e.target.value)} />
        <TextInput label="Email ID" placeholder="Enter email id" required value={ubo.email} onChange={(e) => onChangeUBO(index, 'email', e.target.value)} />
        { countries && <Select label="Country" searchable  nothingFound="No options" required data={countries} value={ubo.country} onChange={(value) => onChangeUBO(index, 'country', value || '')} /> }
         <NumberInput
          label="Share holding"
          min={0}
          max={100}
          placeholder="Share holding in percentage"
          required
          value={ubo.share_holding}
          onChange={(val) => {
            const limitedValue = val !== null ? Math.min(val ?? 0, 100) : 0; 
            onChangeUBO(index, 'share_holding', limitedValue);
          }}
          />
        { ubos.length > 1 && <Button mt={29} color="red" onClick={() => onRemoveUBO(index)}><IconTrash /></Button> }
      </Group>
    ))}
    <Button onClick={onAddUBO} mt="md" leftIcon={<IconPlus />}> Add UBO</Button>
  </>
);

UBOFields.defaultProps = {
  countries: [
    { label: '', value: '' }, // Default empty country object
  ],
}

export default UBOFields;
