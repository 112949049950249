import React from 'react';
import { Group, Skeleton, HoverCard } from '@mantine/core';

import { useAuth0 } from '@auth0/auth0-react';

import { PATH_ROOT, PATH_DASHBOARD, PATH_SETTINGS_BASIC } from 'shared/constants';
import logo from 'assets/tokenfolio-black.svg';
import TopNavigationRightAuthenticated from './TopNavigationRightAuthenticated';
import TopNavigationRightUnauthenticated from './TopNavigationRightUnauthenticated';
import { Tooltip, Button } from '@mantine/core';
import { Link, NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectShouldConfirm } from 'pages/settings/SettingsSlice';
import './TopNavigation.scss';

const getButtonStyles = (isDisabled: boolean, isActive: boolean) => ({
  root: {
    color: isActive ? 'var(--mantine-color-primary-6)' : 'var(--mantine-color-dark-7)',
    ...(isDisabled
      ? {}
      : {
          '&:hover': {
            color: 'var(--mantine-color-primary-6)',
          },
        }),
    borderRadius: 4,
  },
});

const MyButton = ({ isDisabled }: { isDisabled: boolean }) => (
  <HoverCard
    position="bottom"
    withArrow
    shadow="md"
    disabled={!isDisabled} // Only show HoverCard when disabled
    withinPortal
  >
    <HoverCard.Target>
      <div style={{ display: 'inline-block', cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
        <NavLink
          to={isDisabled ? '#' : '/dashboard'}
          style={{ textDecoration: 'none' }}
        >
          {({ isActive }) => (
            <Button
              variant="subtle"
              styles={() => getButtonStyles(isDisabled, isActive)}
              disabled={isDisabled}
            >
              Dashboard
            </Button>
          )}
        </NavLink>
      </div>
    </HoverCard.Target>
    <HoverCard.Dropdown>
      {isDisabled && (
        <div style={{ width: 400 }}>
          Complete identity verification in the Basic Settings to access the dashboard.
          <Link
            to={PATH_SETTINGS_BASIC}
            style={{ color: 'var(--mantine-color-primary-6)', textDecoration: 'underline' }}
          >
           Click here to proceed.
          </Link>{' '}
        </div>
      )}
    </HoverCard.Dropdown>
  </HoverCard>
);

const TopNavigation: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();  
  const hasDashboardAccess = useAppSelector(selectShouldConfirm);

  const topNavigationRight = isAuthenticated ? (
    <TopNavigationRightAuthenticated />
  ) : (
    <TopNavigationRightUnauthenticated />
  );

  return (
    <nav className="top-navigation">
      <Group position="left" className="top-navigation__left-side">
        <Link to={PATH_ROOT}>
          <img className="top-navigation__left-side__logo" src={logo} alt="Tokenfolio" />
        </Link>
      </Group>
      <Group position="right" spacing="lg" className="top-navigation__right-side">
        {/* {hasDashboardAccess && <MyButton />} */}
        <MyButton 
          isDisabled={hasDashboardAccess}
        />
        {isLoading ? <Skeleton height={36} width={36} radius={36} /> : topNavigationRight}
      </Group>
    </nav>
  );
};

export default TopNavigation;
