import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { ref, getBytes } from 'firebase/storage';
import { Parser } from 'xml2js';

import { CompanyApi, CompanyListApi } from 'models/company/company.api.model';
import { Company, CompanyOverview, CompanyRoot } from 'models/company/company.model';
import { firebaseStorage } from 'services/firebase/firebase.service';

import { createBaseQuery } from '../../utils/apiUtils';
import backendConfig from 'config/backend.config';
import { RootState } from 'store/store';
import moment from 'moment';

import { mapCompanyApiToCompany, mapCompanyListApiToCompanyList } from './company.service.utils';

const xmlParser = new Parser({
  trim: true,
  normalize: true,
  mergeAttrs: true,
  strict: true,
  explicitArray: false,
  charkey: 'value',
  preserveChildrenOrder: true,
});

const textDecoder = new TextDecoder();

const getCompanyListQueryFn = () => {
  const now = moment();
  const startDate = now.clone().subtract(6, 'months').startOf('day').toISOString();
  const endDate = now.clone().add(30, 'days').endOf('day').toISOString();

  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  // Return only the URL
  return `/deals?start_date=${encodedStartDate}&end_date=${encodedEndDate}`;
};

const getCompanyQueryFn = async (companyId: string) => {
  const companyXMLBytes = await getBytes(ref(firebaseStorage, `companies/${companyId}/profile.xml`));
  const companyXMLString = textDecoder.decode(companyXMLBytes);
  const companyApiData = (await xmlParser.parseStringPromise(companyXMLString)) as CompanyApi;
  return { data: mapCompanyApiToCompany(companyApiData) };
};

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getCompany: builder.query<Company, string>({
      query: getCompanyQueryFn,
    }),
    getCompanyList: builder.query<CompanyRoot, void>({
      query: getCompanyListQueryFn,
    }),
  }),
});


export const { useGetCompanyQuery, useGetCompanyListQuery } = companyApi;
