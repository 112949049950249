import React from 'react';
import { Loader } from '@mantine/core';
import AGGridTable from './AGGridTable'; 
import PortfolioCard from './PortfolioCard';
import { useGetPortfolioWithAuth } from '../hooks/useGetPortfolioWithAuth'; // Import your custom hook
import './Investors.scss';


const DashboardPortfolio: React.FC = () => {
  const { data, error, isLoading, isAuthLoading, isAuthenticated } = useGetPortfolioWithAuth();

  if (isAuthLoading || isLoading) {
    return (
      <div className="investors-loader-wrapper">
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="investors-loader-wrapper">
        <p>Please login to view this page</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="investors-loader-wrapper">
        Something went wrong. Please refresh the page.
      </div>
    );
  }

  const investments = data?.investments || [];
  const commitments = data?.commitments || [];
  const totalInvested = data?.total_invested || 0;

  const { current_value: currentValue, gain_loss: gainLoss, return: totalReturn, total_committed: totalCommitted } = data || {};

  return (
    <div className="investors-page">
      {data ? (
        <>
          <div className="investors-dashboard-container">
            <PortfolioCard
                totalInvested={totalInvested}
                holdings={investments.length}
                currentValue={currentValue || 0}
                gainLoss={gainLoss || 0}
                totalReturn={totalReturn || 0}
            />
          </div>
          <h4>List of Investments</h4>

          <div className="investors-table-container">
            <AGGridTable rowData={[...commitments, ...investments]} />
          </div>
        </>
      ) : (
        <div className="investors-loader-wrapper">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DashboardPortfolio;
