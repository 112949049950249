import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SimpleGrid } from '@mantine/core';
import { useSelector } from 'react-redux';
// import { SimpleGridProps } from '@mantine/core/lib/SimpleGrid/SimpleGrid';
import { hideNotification, showNotification } from '@mantine/notifications';

import { companyListLoadErrorNotification } from 'pages/homepage/HomepageNotifications';
import { useGetCompanyListQuery } from 'services/company/company.service';

import CompaniesSkeleton from './CompaniesSkeleton';
import CompanyCard from './CompanyCard';

type Props = any;

const Companies: React.FC<Props> = ({ ...props }) => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;
  const { data: companies, error, isLoading } = useGetCompanyListQuery(undefined, { skip });

  useEffect(() => {
    if (error) {
      showNotification(companyListLoadErrorNotification);
    }
    return () => {
      hideNotification(companyListLoadErrorNotification.id as string);
    };
  }, [error]);

  if (error || isLoading || !companies) {
    return <CompaniesSkeleton />;
  }
  return (
    <SimpleGrid breakpoints={[{ maxWidth: 768, cols: 1, spacing: 'sm' }]} cols={4} spacing="xl" {...props}>
      {companies?.items?.map(({ id, banner_url, location, logo_url, company_name, company_summary, tags, end_date, start_date, active }) => {
        const isDealExpired = moment(end_date).isBefore(moment()); // Check if the end_date has passed
        const companyCard = (
          <CompanyCard
            key={id}
            bannerUrl={banner_url}
            location={location}
            logoUrl={logo_url}
            name={company_name}
            summary={company_summary}
            tags={tags}
            dealEnd={end_date}
            dealStart={start_date}
          />
        );

        return isDealExpired || !active ? (
          <div key={id} style={{ cursor: 'not-allowed' }}>{companyCard}</div> // Render only the card if the deal has expired
        ) : (
          <Link key={id} to={id}>
            {companyCard} 
          </Link>
        );
      })}
    </SimpleGrid>
  );
};

export default Companies;
