import { useState, useCallback } from 'react';

/**
 * useToggle is a custom hook that manages a boolean state.
 * It provides a state value, a function to toggle the state, and a function to set the state explicitly.
 *
 * @param initialState - The initial state value (default is false).
 * @returns A tuple containing:
 *   - The current state value.
 *   - A function to toggle the state.
 *   - A function to set the state explicitly.
 */
const useToggle = (initialState = false) => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  const set = useCallback((value: boolean) => {
    setState(value);
  }, []);

  return [state, toggle, set] as const;
};

export default useToggle;
