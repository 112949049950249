import React, { useState, useMemo }  from 'react';
import { Radio, Title, Button, Text, Modal, Box , Stack} from '@mantine/core';
import { useGetEntitiesQuery, useVerifyIdentyMutation } from 'services/invest/entities.service'; // Assuming this is the path for the API file
import { EntityStatus, EntityType } from 'shared/enums';
import { Entity } from 'models/invest/entity.api.model'
import { IconPlus } from '@tabler/icons';
import backendConfig from 'config/backend.config';
import { fetchData } from 'utils/apiUtils';
import { useSelector } from 'react-redux';
import RadioCard from 'components/Common/RadioCard/RadioCard';
import LoadingOverlay from 'components/Common/LoadingOverlay';
import EntityModal from '../../../components/Common/Entity/components/EntityModal';
import NewInvestmentMessage from './NewInvestmentMessage';
import PersonaComponent from '../../Kyc/PersonaComponentNew';
import useToggle from 'hooks/useToggle';
import { toast } from 'react-toastify';
import DynamicMessageModal from './DynamicMessageModal';

let selectedEntity: Entity | undefined;
const RadioCardGroup = ({ value, setValue }: { value: string | undefined, setValue: (data: Entity | undefined) => void }) => {
    const token = useSelector((state: any) => state.auth0.token);
    const skip = !token;
  // Fetch entities, skip query if authentication isn't ready
    const { data: entities , error, isLoading, refetch } = useGetEntitiesQuery(undefined, { skip });

    const [opened, toggleOpened, setOpened] = useToggle(false);
    const [isPersonaOpen, togglePersonaOpen, setIsPersonaOpen] = useToggle(false);

    const [modalType, setModalType] = useState<'static-message' | 'ubo-message' | null>(null);

    const [isLoadingStatus, toggleStatus, setLoading] = useToggle(false);

    const [mode, setMode] = useState<'new' | 'edit'>('new');
    const [editData, setEditData] = useState<Entity | null>(null); // Will be filled for "edit"
    const [verificationTokens, setVerificationTokens] = useState<[any] | []>([]);
    const [currentVerificationToken, setCurrentVerificationToken] = useState<{ inquiry_id: string, session_token: string } | null>(null);
    const hasIndividualEntity = useMemo(() => entities?.some(entity => entity.type === 'INDIVIDUAL'), [entities])

    const [ verifyIdenty ] = useVerifyIdentyMutation();

    const fetchVerificationTokens = async (entity: Entity) => {
        // Mock API call to fetch verification token
        try {
          const data = await fetchData({
            url: `${backendConfig.baseUrl}v1/inquiries?entity_id=${entity.id}`,
            method: 'GET',
            token,
          });
          return data;
        } catch (err) {
          console.error('Error fetching token:', err);
          return [];
        }
      };

    const handleStatusClick = async (entity: Entity) => {
        selectedEntity = entity;
    
        if (entity.status === "NOT_VERIFIED") {
            if (entity.country === "USA" || entity.type === "INDIVIDUAL") {
                try {
                    setLoading(true); 
                    const personaTokens = await fetchVerificationTokens(entity);
                    setLoading(false); // Hide loading overlay
                    if (personaTokens.length > 0) {
                        setVerificationTokens(personaTokens);
                        const [nextToken, ...remainingTokens] = personaTokens;
                        setCurrentVerificationToken({...nextToken});
                        setVerificationTokens(remainingTokens);
                        setIsPersonaOpen(true);
                    } else if(entity.type !== "INDIVIDUAL"){
                        setModalType('ubo-message');
                    }
                } catch (err) {
                    setLoading(false); 
                    toast.error(`Something went wrong. Please try after sometime`);
                }
            } else {
                setModalType('static-message');
            }
        }
    };
    

    const handlePersonaCloseModel = () => {
        setIsPersonaOpen(false);
        setVerificationTokens([]);
    }

    const handlePersonaSuccess = () => {
        if (verificationTokens.length > 0) {
            const [nextToken, ...remainingTokens] = verificationTokens;
            setCurrentVerificationToken({ ...nextToken });
            setVerificationTokens([...remainingTokens]);
        } else {
            setIsPersonaOpen(false);
            refetch();

            if(selectedEntity?.type !== "INDIVIDUAL") {
                setModalType('ubo-message');
            }
        }
    };
    
    const navigateToNewEntity = () => {
        setEditData(null);
        setMode('new');
        setOpened(true);
    }

    const setEntities = (id: string) => {
        const entity = entities?.find((data: Entity) => data.id === id);
        setValue(entity);
    }

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching entities </div>;

    return (
        <>
        <Radio.Group
            value={value}
            label={<Title order={4}>Who is investing?</Title>}
            onChange={setEntities}
            orientation="vertical"
            spacing="sm"
         >
        <>
            {entities?.map((entity: Entity) => {
            const statusValue = entity.status as keyof typeof EntityStatus;
            const status = EntityStatus[statusValue];

            const typeLabel = EntityType[entity.type as keyof typeof EntityType]?.label || 'Unknown type';

            return (
            <RadioCard
                key={entity.id}
                value={entity.id}
                title={entity.legal_name || entity.first_name || ''}
                description={typeLabel} // Pass the type label
                status={status?.label} // Pass the status label
                color={status?.color}
                statusClickDisabled={entity.status !== 'NOT_VERIFIED'}
                onStatusClick={() => handleStatusClick(entity)} // Pass the status value to retrieve URL
                isSelected={value === entity.id}
                onChange={setEntities}
            />
            );
        })}

        {!entities || !entities.length ?
          <>
           <NewInvestmentMessage
            onNewEntityClick={navigateToNewEntity}
           />
           <Text color="red"> <i> Note: </i>A valid investment entity is required for investing.</Text>
          </>:
          <>
            <Button leftIcon={<IconPlus />} variant="subtle" onClick={navigateToNewEntity}>
                New Investment Entity
            </Button>
           {  !value && <Text color="red"> <i> Note: </i>A valid investment entity is required for investing.</Text> }
           </>
        }

         {opened && (
            <EntityModal
                opened={opened}
                setOpened={setOpened}
                mode={editData ? 'edit' : 'new'} // Determine mode based on the presence of editData
                initialData={editData} // Pass initial data for editing, empty for new
                disabledIndividual = {hasIndividualEntity || false}
            />
         )}
   </>
      </Radio.Group>

        {isPersonaOpen && currentVerificationToken && (
            <PersonaComponent
                opened={isPersonaOpen}
                key={currentVerificationToken.inquiry_id}
                inquiryId={currentVerificationToken.inquiry_id}
                onComplete={handlePersonaSuccess}
                onCloseModel={handlePersonaCloseModel}
                sessionToken={currentVerificationToken.session_token}
            />
        )}
       
        {modalType && (
            <DynamicMessageModal
                isVisible={!!modalType}
                onClose={() => setModalType(null)}
                type={modalType}
                data={selectedEntity}
            />
        )}     

        {isLoadingStatus && <LoadingOverlay />}
      </>
    );
  };

  export default RadioCardGroup;
