import React from "react";
import { Button } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { fetchData } from 'utils/apiUtils';
import backendConfig from 'config/backend.config';
import { IUboType } from "models/invest/entity.api.model";
import { TRANSLATE_STRING } from "i18n/messages";


const UboTable: React.FC<{ ubos: IUboType[], showStatusColumn?: boolean }> = ({ ubos, showStatusColumn }) => {
  const { getAccessTokenSilently } = useAuth0();

  const handleResendEmail = async (uboId: string) => {
    const token = await getAccessTokenSilently();
    try { 
      await fetchData({
        url: `${backendConfig.baseUrl}v1/inquiries/restart?entity_id=${uboId}`,
        method: 'GET',
        token
      });
      toast.success('Email has been resent successfully and will arrive shortly.');
    } 
    catch {
      toast.error('Something went wrong, please try after sometime.');
    }
  }

  return (
    <div>
      <strong>UBO details:</strong>
      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>First Name</th>
            <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Email</th>
            {showStatusColumn ? (
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Status</th>
            ) : (
              <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Share Holding (%)</th>
            )}
          </tr>
        </thead>
        <tbody>
          {ubos.map((ubo) => (
            <tr key={ubo.email}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{ubo.first_name}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{ubo.email}</td>
             
              {showStatusColumn ? (
                <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  color: ubo.status === "VERIFIED" ? "green" : "red",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {TRANSLATE_STRING[ubo.status as keyof typeof TRANSLATE_STRING || ''] ?? ubo.status}
                </span>
                {ubo.status === "NOT_VERIFIED" && (
                  <Button
                    variant="outline"
                    onClick={() => handleResendEmail(ubo.id || "")}
                    style={{ marginLeft: "auto" }}
                  >
                    Resend email
                  </Button>
                )}
              </td>
              
              ) : 
             (
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{ubo.share_holding}%</td>
             )
            }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


  UboTable.defaultProps = {
    showStatusColumn: false
  }

export default UboTable;
