import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, Group } from '@mantine/core';
import EntityStepper from './EntityStepper';

interface EntityModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  mode: 'new' | 'edit';  // Define the mode
  initialData?: any;  // Data for editing, if available
  disabledIndividual: boolean
}

const EntityModal: React.FC<EntityModalProps> = ({ opened, setOpened, mode, initialData = {}, disabledIndividual }) => {
  const [entityType, setEntityType] = useState<string | null>(initialData?.type || null);

  useEffect(() => {
    if (mode === 'edit') {
      setEntityType(initialData.entityType);  // Pre-fill entity type if editing
    }
  }, [mode, initialData]);

  const handleEntitySelection = (value: string) => {
    setEntityType(value);
  };

  return (
    <Modal opened={opened} onClose={() => setOpened(false)} size="70%" title={mode === 'edit' ? 'Edit Entity' : 'Create your new investment entity.'}
     styles={{
        modal: {
          display: 'flex',
          flexDirection: 'column',
          height: '80vh', // Fixed height
        },
        body: {
          flexGrow: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
        <div style={{ flex: 1, overflowY: 'auto' }}>
            {mode === 'new' && !entityType ? (
                <Select
                label="Select Entity Type"
                placeholder="Pick one"
                data={[
                    { value: 'INDIVIDUAL', label: 'Individual', disabled: disabledIndividual },
                    { value: 'COMPANY', label: 'Firm' },
                ]}
                onChange={handleEntitySelection}
                />
            ) : (
                    <EntityStepper
                    entityType={entityType}
                    setOpened={setOpened}
                    initialData={initialData}
                    />

            )}
        </div>

      {!entityType && mode === 'new' && (
        <Group position="right" mt="md" style={{ paddingTop: '1rem', borderTop: '1px solid #e0e0e0' }}>
          <Button onClick={() => setOpened(false)}>Cancel</Button>
        </Group>
      )}
    </Modal>
  );
};

EntityModal.defaultProps = {
    initialData: {}
}

export default EntityModal;
